import React from 'react';
import Modal from '../../../../../common/modal/Modal';

const MergeModal = ({ isOpen, onClose, onAction, metaData }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} modalTitle="Step4/4: Merge Allowlist Employees">
      <div className="form-group">
        <h6>Summary of match plan</h6>
        <div className={'d-flex justify-content-between mx-2'}>
          <div> Current Records Linked With EgymAccounts</div>
          <div> {metaData.currentAllowlistRecordsLinkedWithEgymAccounts}</div>
        </div>
        <div className={'d-flex justify-content-between mx-2'}>
          <div>New Records Linked With EgymAccounts</div>
          <div> {metaData.newAllowlistRecordsLinkedWithEgymAccounts}</div>
        </div>
        <div className={'d-flex justify-content-between mx-2'}>
          <div>Current Allowlist Size</div>
          <div> {metaData.currentAllowlistSize}</div>
        </div>
        <div className={'d-flex justify-content-between mx-2'}>
          <div>New Allowlist Size</div>
          <div> {metaData.newAllowlistSize}</div>
        </div>
        <div className={'d-flex justify-content-between mx-2'}>
          <div>Matched allowlist records</div>
          <div> {metaData.numberOfMatchedAllowlistRecords}</div>
        </div>
        <br></br>
        <label htmlFor="offerId" className={'text-primary'}>
          Note: Please perform this action only if you agree with the match plan in the downloaded
          csv file.
        </label>
      </div>

      <div className="form-group form-row">
        <div className="col-sm-9" />
        <button className="btn btn-secondary col-sm-3" type="button" onClick={onAction}>
          Merge
        </button>
      </div>
    </Modal>
  );
};

export default MergeModal;
