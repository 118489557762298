// @flow
import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { httpGet, httpPost } from 'app/service/http';
import CompanyRevenueTableRow from 'app/ui/gyms/gym/payout/steps/adjusted_revenues/CompanyRevenueTableRow.jsx';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { exportAdjustedRevenues } from 'app/ui/gyms/gym/payout/steps/adjusted_revenues/AdjustedRevenuesCsvExporter.js';
import PayoutStepper from '../../PayoutStepper';

type Props = {
  step: any,
};

const AdjustRevenuesPage = ({ step }: Props) => {
  const { calculationRunId } = useParams();
  const { executeWithSpinner } = useContext(SpinnerContext);
  const [calculationRun, setCalculationRun] = useState(null);
  const [meditationContentCost, setMeditationContentCost] = React.useState(0);
  const [validationError, setValidationError] = useState(null);
  const [companyRevenues, setCompanyRevenues] = useState(null);
  const [error, setError] = useState(null);
  const [isCalculationBtnDisabled, setIsCalculationBtnDisabled] = useState(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getCompanyRevenues = async () => {
      try {
        await httpGet(`/v1/payout/calculation/${calculationRunId}`).then(calculationRun => {
          setCalculationRun(calculationRun);
          setIsCalculationBtnDisabled(!!calculationRun.startedAt);
          setIsNextButtonDisabled(!calculationRun.startedAt);
        });

        await httpGet(`/v1/payout/calculation/${calculationRunId}/company-revenue`).then(
          companyRevenueResponse => {
            setCompanyRevenues(companyRevenueResponse);
            setLoading(false);
          }
        );
      } catch (error) {
        setError(error && error.message);
        setLoading(false);
      }
    };
    executeWithSpinner(getCompanyRevenues());
  }, [calculationRunId, setCompanyRevenues, setError, executeWithSpinner]);

  const handleNextClick = () => {
    navigate(step.to);
  };

  const handleBackClick = () => {
    navigate(step.from);
  };

  const handleCalculateClick = () => {
    return executeWithSpinner(
      httpPost(`/v1/payout/calculation/${calculationRunId}/trigger-calculation-job`)
        .then(() => {
          navigate(step.to);
        })
        .catch(error => {
          setError(error && error.message);
        })
    );
  };

  const deductMeditationContentCost = () => {
    if (meditationContentCost <= 0) {
      setValidationError('Incorrect value. Should be bigger than 0');
      return;
    }

    return executeWithSpinner(
      httpPost(
        `/v1/payout/calculation/${calculationRunId}/meditation-content-costs/${meditationContentCost}`
      )
        .then(updatedCompanyRevenues => {
          setCompanyRevenues(updatedCompanyRevenues);
        })
        .catch(error => {
          setError(error && error.message);
        })
    );
  };

  return (
    <>
      <PayoutStepper currentPath={window.location.pathname} />
      <div className="form-group form-row">
        <div className="col-sm-2">
          <button onClick={() => handleBackClick()} className="btn btn-block btn-primary m-2">
            Back
          </button>
        </div>
        <div className="col-sm-3">
          <button
            disabled={
              error || !companyRevenues || isCalculationBtnDisabled || calculationRun.startedAt
            }
            onClick={() => handleCalculateClick()}
            className="btn btn-block btn-secondary m-2"
          >
            Calculate
          </button>
        </div>
        <div className="col-sm-2">
          <button
            disabled={error || isNextButtonDisabled}
            onClick={() => handleNextClick()}
            className="btn btn-block btn-primary m-2"
          >
            Next
          </button>
        </div>
        <div className="col-sm-5" />
      </div>
      <div className="form-row mb-4 mt-3">
        <label htmlFor="meditationContentCost" className="col-sm-2 col-form-label">
          Amount to be distributed:
        </label>
        <div className="col-sm-3 input-group">
          <input
            id="meditationContentCost"
            type="number"
            min="0"
            disabled={!companyRevenues}
            value={meditationContentCost}
            className={`form-control text-right ${validationError ? ' is-invalid' : ''}`}
            onChange={event => {
              setValidationError(null);
              setMeditationContentCost(event.target.value);
            }}
            required
          />
          <div className="input-group-append">
            <span className="input-group-text rounded-right" id="meditationContentCost">
              <i className="fas fa-xs inline-img fa-euro-sign" />
            </span>
          </div>
          {validationError && <div className="invalid-feedback">{validationError}</div>}
        </div>
        <div className="col-sm-2 ml-2">
          <button
            disabled={error || !meditationContentCost || meditationContentCost === '0'}
            onClick={() => deductMeditationContentCost()}
            className="btn btn-block btn-secondary"
          >
            Adjust revenues
          </button>
        </div>
      </div>
      <div className="form-group form-row">
        {error ? (
          <div className="col-12 text-center alert alert-primary">{error}</div>
        ) : companyRevenues ? (
          <>
            <div className="row col-md-12">
              <div className="col-md-4 mt-2 font-italic">
                {companyRevenues.length} companies found
              </div>
              <button
                className="col-md-2 offset-md-6 mb-1  btn btn-primary"
                onClick={() => exportAdjustedRevenues(companyRevenues, calculationRunId)}
              >
                Export CSV
              </button>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Salesforce Account Id</th>
                  <th>Company Name</th>
                  <th>Revenue</th>
                  <th>Adjusted Revenue</th>
                  <th>Comment</th>
                  <th>Revenue Last Month</th>
                  <th>Comment Last Month</th>
                </tr>
              </thead>
              <tbody>
                {companyRevenues.map((revenue, index) => (
                  <CompanyRevenueTableRow
                    revenue={revenue}
                    key={index}
                    isEditable={!!calculationRun.startedAt}
                  />
                ))}
              </tbody>
            </table>
          </>
        ) : (
          !loading && (
            <div className="col-12 text-center alert alert-info">
              No revenue data available for this payout run. Please go back to the gym payout
              calculation {<Link to={`/gyms/payout-calculation`}>overview</Link>} and proceed from
              there.
            </div>
          )
        )}
      </div>
    </>
  );
};

export default AdjustRevenuesPage;
