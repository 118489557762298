// @flow
import * as React from 'react';
import { useState } from 'react';
import { httpGet, httpPost } from 'app/service/http';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import moment from 'moment-timezone';
import { dateToCET } from 'app/utils/date/dateUtil';
import { useParams, useNavigate } from 'react-router-dom';
import PayoutStepper from '../PayoutStepper';

const dateFormat = 'YYYY-MM-DD HH:mm';

type Props = {
  step: any,
  navigationHandler: any,
};

const FetchDataFromSfPage = ({ step }: Props) => {
  const { calculationRunId } = useParams();
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [lastSfFetchTimestamp, setLastSfFetchTimestamp] = useState('');
  const [isPayoutDataExists, setIsPayoutDataExists] = useState(false);
  const [allowedFetchDate, setAllowedFetchDate] = useState('');
  const [runPeriod, setRunPeriod] = useState('');

  const [isFetchDataBtnDisabled, setIsFetchDataBtnDisabled] = useState(true);
  const [errorResult, setErrorResult] = useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchSfFetchedTimeForCurrentCalculation = () => {
      return executeWithSpinner(
        httpGet(`/v1/salesforce/company/cache-refresh-timestamp`)
          .then(lastSfFetchTimestampResponse => {
            const lastSfFetchTimestampDate = moment(lastSfFetchTimestampResponse.timestamp);
            setLastSfFetchTimestamp(lastSfFetchTimestampDate);
            return lastSfFetchTimestampDate;
          })
          .then(lastSfFetchTimestampDate => {
            httpGet(`/v1/payout/calculation/${calculationRunId}`)
              .then(calculationRunResponse => {
                setRunPeriod(calculationRunResponse.period);
                const sfDataFetchedAtForCurrentCalculation = calculationRunResponse.finishedAt;
                const sfDataFetchedBtnEnabledDate =
                  calculateNextEnableDateForFetchDataBtn(lastSfFetchTimestampDate);
                const isDisabledByDate = moment().isBefore(sfDataFetchedBtnEnabledDate);

                setIsFetchDataBtnDisabled(sfDataFetchedAtForCurrentCalculation || isDisabledByDate);
                setAllowedFetchDate(sfDataFetchedBtnEnabledDate);
                if (sfDataFetchedAtForCurrentCalculation) {
                  setIsPayoutDataExists(true);
                }
              })
              .catch(error => {
                setErrorResult(error && error.message);
                setIsFetchDataBtnDisabled(true);
              });
          })
          .catch(error => {
            setErrorResult(error && error.message);
            setIsFetchDataBtnDisabled(true);
          })
      );
    };

    fetchSfFetchedTimeForCurrentCalculation();
  }, [calculationRunId, executeWithSpinner]);

  const calculateNextEnableDateForFetchDataBtn = lastSfDataFetchedAt => {
    return moment(lastSfDataFetchedAt).add(5, 'minutes');
  };

  const goBack = () => {
    navigate(step.from);
  };

  const goNext = () => {
    navigate(step.to);
  };

  const fetchDataFromSalesforce = () => {
    return executeWithSpinner(
      httpPost(`/v1/payout/calculation/${calculationRunId}/fetch-data-from-sf/${runPeriod}`)
        .then(sfDataFetchedAt => {
          setIsFetchDataBtnDisabled(true);
          const formattedSfDataFetchedAt = moment(sfDataFetchedAt);
          setLastSfFetchTimestamp(formattedSfDataFetchedAt);
          const targetDate = calculateNextEnableDateForFetchDataBtn(formattedSfDataFetchedAt);
          setAllowedFetchDate(targetDate);
          setIsPayoutDataExists(true);
        })
        .catch(error => {
          setErrorResult(error && error.message);
          setIsFetchDataBtnDisabled(true);
        })
    );
  };

  const isNextBtnDisabled = () => {
    const runPeriodFirstDayOfMonth = moment(`${runPeriod}`).startOf('month');
    const currentFirstDayOfMonth = moment().startOf('month');
    return currentFirstDayOfMonth < runPeriodFirstDayOfMonth;
  };

  return (
    <>
      <PayoutStepper currentPath={window.location.pathname} />
      <div className="form-row">
        <div className="col-12">
          <div className="form-group">
            {errorResult && <div className="alert alert-primary">{errorResult}</div>}
            <button className="btn btn-primary col-2 m-2" onClick={() => goBack()}>
              Back
            </button>
            <button
              className="btn btn-secondary col-3 m-2"
              onClick={() => fetchDataFromSalesforce()}
              disabled={isFetchDataBtnDisabled}
            >
              Fetch data from Salesforce
            </button>
            <button
              className="btn btn-primary col-2 m-2"
              onClick={() => goNext()}
              disabled={!isPayoutDataExists && isNextBtnDisabled()}
            >
              Next
            </button>
            <div className="form-row mt-2">
              <div className="col-md-12 ml-1 mt-2">
                {lastSfFetchTimestamp && lastSfFetchTimestamp.length !== 0 && (
                  <p>
                    Salesforce cache last refreshed at:{' '}
                    <strong>{dateToCET(lastSfFetchTimestamp, dateFormat)}</strong>
                  </p>
                )}
                {allowedFetchDate && !isPayoutDataExists && allowedFetchDate.length !== 0 && (
                  <p>
                    Next fetch data will be available at:{' '}
                    <strong>{dateToCET(allowedFetchDate, dateFormat)}</strong>
                  </p>
                )}
              </div>
            </div>
            {isPayoutDataExists && (
              <div className="alert alert-info text-center">
                <strong>Already fetched sf data for payout calculation with ID:</strong>{' '}
                {calculationRunId}
              </div>
            )}
            <div className="alert alert-warning mt-3">
              Fetch data from Salesforce" will fetch all corporate and gym information from
              Salesforce. This is a costly operation - please handle with care.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FetchDataFromSfPage;
