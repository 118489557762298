import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { httpGet, httpPatch } from 'app/service/http';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { dateTimeToDateTimeCET } from 'app/utils/date/dateUtil';
import { downloadFileFromUrl } from 'app/utils/file/fileUtil';
import PayoutConfirmationModal from 'app/ui/gyms/gym/payout/steps/complete_review_payout/PayoutConfirmationModal';
import PayoutStepper from '../../PayoutStepper';

type Props = {
  step: any,
};

const CompleteReviewPayoutPage = ({ step }: Props) => {
  const { calculationRunId } = useParams();
  const [calculation, setCalculation] = useState(null);
  const [files, setFiles] = useState(null);
  const [showPayoutConfirmationSection, setShowPayoutConfirmationSection] = useState(false);
  const [errorResult, setErrorResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPayoutConfirmationModalOpen, setIsPayoutConfirmationModalOpen] = useState(false);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const navigate = useNavigate();

  const getPayoutCalculation = useCallback(async () => {
    try {
      const calculationResponse = await httpGet(`/v1/payout/calculation/${calculationRunId}`);
      setCalculation(calculationResponse);

      const confirmedCalculationExistenceResponse = await httpGet(
        `/v1/payout/calculation/confirmed-calculation-run-exists/${calculationResponse.period}/${calculationResponse.network}`
      );
      setShowPayoutConfirmationSection(
        !confirmedCalculationExistenceResponse.confirmedCalculationRunExists ||
          calculationResponse.payoutConfirmedAt != null
      );

      const exportsResponse = await httpGet(`/v1/payout/calculation/${calculationRunId}/exports`);
      setFiles(exportsResponse);
      setLoading(false);
    } catch (error) {
      setErrorResult(error && error.message);
      setLoading(false);
    }
  }, [calculationRunId]);

  useEffect(() => {
    executeWithSpinner(getPayoutCalculation());
  }, [executeWithSpinner, getPayoutCalculation]);

  useEffect(() => {
    if (calculation !== null && !calculation.finishedAt) {
      const timeout = setTimeout(() => getPayoutCalculation(), 10000);

      return () => clearTimeout(timeout);
    }
  }, [getPayoutCalculation, calculation]);

  const downloadFile = file => {
    downloadFileFromUrl(`/v1/payout/calculation/${calculationRunId}/exports/${file}`, file);
  };

  const handleBackClick = () => {
    navigate(step.from);
  };

  const openCalculationConfirmationModal = () => {
    setIsPayoutConfirmationModalOpen(true);
  };

  const cancelCalculationConfirmationModal = () => {
    setIsPayoutConfirmationModalOpen(false);
  };

  const confirmCalculation = () => {
    const confirmCalculationAndDeleteUnconfirmed = async () => {
      try {
        const confirmedCalculation = await httpPatch(
          `/v1/payout/calculation/${calculation.id}/confirm`
        );

        setCalculation(confirmedCalculation);
      } catch (error) {
        setErrorResult(error && error.message);
      }
    };

    executeWithSpinner(confirmCalculationAndDeleteUnconfirmed());
    cancelCalculationConfirmationModal();
  };

  return (
    <>
      <PayoutStepper currentPath={window.location.pathname} />
      <div className="form-row">
        <div className="col-12">
          <div className="form-group">
            {errorResult && <div className="alert alert-primary">{errorResult}</div>}
            <div>
              <div className="col-sm-2">
                <button onClick={() => handleBackClick()} className="btn btn-primary m-2">
                  Back
                </button>
              </div>
              {!loading && calculation && (
                <div>
                  {showPayoutConfirmationSection && (
                    <div className="offset-1 row mt-2">
                      <div className="col-4">The payout calculation was confirmed: </div>
                      {calculation.payoutConfirmedAt ? (
                        <div className="col-3">
                          {dateTimeToDateTimeCET(calculation.payoutConfirmedAt)}
                        </div>
                      ) : (
                        <div className="col-4">
                          <button
                            disabled={!calculation.finishedAt}
                            onClick={() => openCalculationConfirmationModal()}
                            className="btn btn-primary btn-sm"
                          >
                            CONFIRM PAYOUT FOR {calculation.period}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="offset-1 row mt-2">
                    <div className="col-4">The payout calculation was completed: </div>
                    <div className="col-3">{dateTimeToDateTimeCET(calculation.finishedAt)}</div>
                  </div>
                  <div className="offset-1 row mt-2">
                    <div className="col-4">The payout calculation was started: </div>
                    <div className="col-3">{dateTimeToDateTimeCET(calculation.startedAt)}</div>
                  </div>
                  <div className="offset-1 row mt-2">
                    <div className="col-4">The payout run was created:</div>
                    <div className="col-3">{dateTimeToDateTimeCET(calculation.createdAt)}</div>
                  </div>
                </div>
              )}
              {files && (
                <div className="row mt-5">
                  {files.map(file => (
                    <div className="offset-1 row col-10" key={file}>
                      <div className="col-10">
                        <Link to="#" onClick={() => downloadFile(file)}>
                          {file}
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isPayoutConfirmationModalOpen && (
        <PayoutConfirmationModal
          onConfirm={confirmCalculation}
          onCancel={cancelCalculationConfirmationModal}
        />
      )}
    </>
  );
};

export default CompleteReviewPayoutPage;
