import HorizontalFormTextField from '../../../../../components/horizontal-form/HorizontalFormTextField';
import HorizontalFormRow from '../../../../../components/horizontal-form/HorizontalFormRow';
import HorizontalFormLabel from '../../../../../components/horizontal-form/HorizontalFormLabel';
import { Form } from 'react-final-form';
import { eligibilityRulesOptions } from '../../../../../common/util/dropdownUtil';
import HorizontalFormDropdownField from '../../../../../components/horizontal-form/HorizontalFormDropdownField';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { handleErrorResponse, httpGet, httpPost } from '../../../../../../service/http';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import {
  createEligibilityRulesFormDetail,
  createEligibilityRulesRequestBodyDetail,
} from './createEligibilityRuleDetail';
import WorkLocationOptions from './components/WorkLocationOptions';
import LegalEntityOptions from './components/LegalEntityOptions';
import EmploymentTypeOptions from './components/EmploymentTypeOptions';

import {
  ELIGIBILITY_RULE_OPTION_ACTIONS,
  useEligibilityRuleOption,
} from '../../reducers/useEligibilityRuleOption';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import GroupsOptions from './components/GroupsOptions';

function EligibilityRulesForm({
  integrationScope,
  redirect = () => {
    window.location.reload();
  },
  styles = '',
  forceEnableSubmit = false,
}) {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  const formRef = useRef();
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [savedEligibilityRules, setSavedEligibilityRules] = useState();
  const [loading, setLoading] = useState(true);
  const [eligibilityRuleOption, setEligibilityRuleOption] = useEligibilityRuleOption({
    isLegalEntitySectionOpen: false,
    isWorkLocationSectionOpen: false,
    isEmploymentTypeSectionOpen: false,
    isGroupsSectionOpen: false,
    legalEntities: new Set(),
    workLocations: new Set(),
    employmentTypes: new Set(),
    groups: new Set(),
  });

  const initializeSelectedOptions = useCallback(
    resp => {
      const stateOptions = {
        legalEntities: new Set(),
        workLocations: new Set(),
        employmentTypes: new Set(),
        groups: new Set(),
      };
      Object.keys(stateOptions).forEach(key => {
        if (resp[key]) {
          resp[key].split(',').forEach(l => stateOptions[key].add(l));
        }
      });
      setEligibilityRuleOption({
        action: ELIGIBILITY_RULE_OPTION_ACTIONS.SET_ALL_ELIGIBILITY_OPTIONS,
        payload: stateOptions,
      });
    },
    [setEligibilityRuleOption]
  );

  const handleCustomError = response => {
    if (response.ok) {
      return response.json();
    } else if (response.status !== 404) {
      handleErrorResponse(response);
    }
    return Promise.resolve();
  };

  useEffect(() => {
    const getIntegrationScope = async () => {
      if (integrationScope === undefined) {
        return;
      }
      try {
        await httpGet(
          `/v1/integration-scope/${integrationScope?.id}/eligibility-rule/latest`,
          null,
          handleCustomError
        )
          .then(eligibilityRulesDetail => {
            const dbEligibilityRules = createEligibilityRulesFormDetail(eligibilityRulesDetail);
            initializeSelectedOptions(dbEligibilityRules);
            setSavedEligibilityRules(dbEligibilityRules);
          })
          .catch(error => {
            console.log(error);
          });
      } finally {
        setLoading(false);
      }
    };
    executeWithSpinner(getIntegrationScope());
  }, [executeWithSpinner, initializeSelectedOptions, integrationScope]);

  const createEligibilityRules = async (eligibilityRulesDetail, form) => {
    const { pristine } = form.getState();
    const eligibilityRulesBody = createEligibilityRulesRequestBodyDetail(eligibilityRulesDetail);

    if (forceEnableSubmit && pristine) {
      redirect();
      return;
    }

    try {
      await httpPost(
        `/v1/integration-scope/${integrationScope?.id}/eligibility-rule`,
        eligibilityRulesBody
      );
      redirect();
    } catch (error) {
      console.error('Error creating eligibility rules:', error);
    }
  };

  const changeEligibilityOptionsField = (field, value) => {
    formRef.current.mutators.setEligibilityOptionField(field, value);
  };

  return (
    <div>
      {!loading && (
        <Form
          onSubmit={createEligibilityRules}
          initialValuesEqual={() => true}
          initialValues={{
            ...savedEligibilityRules,
            ...integrationScope,
          }}
          mutators={{
            setEligibilityOptionField: ([fieldName, fieldVal], state, { changeValue }) => {
              changeValue(state, fieldName, () => fieldVal);
            },
          }}
          render={({ handleSubmit, pristine, form }) => {
            formRef.current = form;
            return (
              <form onSubmit={handleSubmit}>
                <h5>Eligibility rules</h5>
                <br />
                <span className="font-weight-bold">Tip:</span>
                <p>
                  If no rules are added for a category, all options will be included. Please be
                  careful to not accidentally exclude certain employee groups. You should
                  anticipate, that new groups will be added over time. Therefore we recommend that
                  you rather define who is excluded than to define who is included.
                </p>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Legal Entity" />
                  <HorizontalFormTextField
                    controlId="legalEntities"
                    controlLabel="legalEntities"
                    placeholder=""
                    columnWidth={5}
                    disabled={true}
                    helpTextAfter={
                      <span
                        className={styles.availableOptions}
                        onClick={() =>
                          setEligibilityRuleOption({
                            action: ELIGIBILITY_RULE_OPTION_ACTIONS.INVERT_LEGAL_ENTITY_DROPDOWN,
                          })
                        }
                      >
                        {isEditable && 'click here to add or remove available options'}
                      </span>
                    }
                  />
                  <HorizontalFormDropdownField
                    controlId="legalEntityCriteria"
                    controlLabel="legalEntityCriteria"
                    options={eligibilityRulesOptions()}
                    columnWidth={3}
                    disabled={!isEditable}
                  />
                </HorizontalFormRow>
                {eligibilityRuleOption.isLegalEntitySectionOpen && (
                  <LegalEntityOptions
                    eligibilityRuleOption={eligibilityRuleOption.legalEntities}
                    setEligibilityRuleOption={setEligibilityRuleOption}
                    changeEligibilityOptionsField={changeEligibilityOptionsField}
                    savedEligibilityRules={savedEligibilityRules?.legalEntities}
                    integrationScopeId={integrationScope?.id}
                  />
                )}
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Work location" />
                  <HorizontalFormTextField
                    controlId="workLocations"
                    controlLabel="workLocations"
                    placeholder=""
                    columnWidth={5}
                    disabled={true}
                    helpTextAfter={
                      <span
                        className={styles.availableOptions}
                        onClick={() =>
                          setEligibilityRuleOption({
                            action: ELIGIBILITY_RULE_OPTION_ACTIONS.INVERT_WORK_LOCATION_DROPDOWN,
                          })
                        }
                      >
                        {isEditable && 'click here to add or remove available options'}
                      </span>
                    }
                  />
                  <HorizontalFormDropdownField
                    controlId="workLocationCriteria"
                    controlLabel="workLocationCriteria"
                    options={eligibilityRulesOptions()}
                    columnWidth={3}
                    disabled={!isEditable}
                  />
                </HorizontalFormRow>
                {eligibilityRuleOption.isWorkLocationSectionOpen && (
                  <WorkLocationOptions
                    eligibilityRuleOption={eligibilityRuleOption.workLocations}
                    setEligibilityRuleOption={setEligibilityRuleOption}
                    changeEligibilityOptionsField={changeEligibilityOptionsField}
                    savedEligibilityRules={savedEligibilityRules?.workLocations}
                    integrationScopeId={integrationScope?.id}
                  />
                )}
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Employment Types" />
                  <HorizontalFormTextField
                    controlId="employmentTypes"
                    controlLabel="employmentTypes"
                    placeholder=""
                    columnWidth={5}
                    disabled={true}
                    helpTextAfter={
                      <span
                        className={styles.availableOptions}
                        onClick={() =>
                          setEligibilityRuleOption({
                            action: ELIGIBILITY_RULE_OPTION_ACTIONS.INVERT_EMPLOYMENT_TYPE_DROPDOWN,
                          })
                        }
                      >
                        {isEditable && 'click here to add or remove available options'}
                      </span>
                    }
                  />
                  <HorizontalFormDropdownField
                    controlId="employmentTypeCriteria"
                    controlLabel="employmentTypeCriteria"
                    options={eligibilityRulesOptions()}
                    columnWidth={3}
                    disabled={!isEditable}
                  />
                </HorizontalFormRow>
                {eligibilityRuleOption.isEmploymentTypeSectionOpen && (
                  <EmploymentTypeOptions
                    eligibilityRuleOption={eligibilityRuleOption.employmentTypes}
                    setEligibilityRuleOption={setEligibilityRuleOption}
                    changeEligibilityOptionsField={changeEligibilityOptionsField}
                    savedEligibilityRules={savedEligibilityRules?.employmentTypes}
                    integrationScopeId={integrationScope?.id}
                  />
                )}
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Groups" />
                  <HorizontalFormTextField
                    controlId="groups"
                    controlLabel="groups"
                    placeholder=""
                    columnWidth={5}
                    disabled={true}
                    helpTextAfter={
                      <span
                        className={styles.availableOptions}
                        onClick={() =>
                          setEligibilityRuleOption({
                            action: ELIGIBILITY_RULE_OPTION_ACTIONS.INVERT_GROUPS_DROPDOWN,
                          })
                        }
                      >
                        {isEditable && 'click here to add or remove available options'}
                      </span>
                    }
                  />
                  <HorizontalFormDropdownField
                    controlId="groupsCriteria"
                    controlLabel="groupsCriteria"
                    options={eligibilityRulesOptions()}
                    columnWidth={3}
                    disabled={!isEditable}
                  />
                </HorizontalFormRow>
                {eligibilityRuleOption.isGroupsSectionOpen && (
                  <GroupsOptions
                    eligibilityRuleOption={eligibilityRuleOption.groups}
                    setEligibilityRuleOption={setEligibilityRuleOption}
                    changeEligibilityOptionsField={changeEligibilityOptionsField}
                    savedEligibilityRules={savedEligibilityRules?.groups}
                    integrationScopeId={integrationScope?.id}
                  />
                )}
                <div className={`d-flex ${styles}`}>
                  <button
                    className="btn btn-block btn-secondary col-sm-3"
                    type="submit"
                    disabled={!forceEnableSubmit && pristine}
                  >
                    Save Eligibility Rules
                  </button>
                </div>
                <br />
              </form>
            );
          }}
        />
      )}
    </div>
  );
}

export default EligibilityRulesForm;
