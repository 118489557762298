import React from 'react';
import Modal from '../../../../../common/modal/Modal';

const MatchPlanModal = ({ isOpen, onClose, onAction }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} modalTitle="Step3/4: Match Planing">
      <div className="form-group">
        <label className={'text-primary'}>
          Download a csv file containing the match between the employees in allowlist and the
          corporate employees in HRIS system.
        </label>
      </div>

      <div className="form-group form-row">
        <div className="col-sm-9" />
        <button className="btn btn-secondary col-sm-3" type="button" onClick={onAction}>
          Match
        </button>
      </div>
    </Modal>
  );
};

export default MatchPlanModal;
